import {
  returnNewArrayById,
  returnNewShopItem,
  removeItemBasket,
  updateObjectInArray,
  updateLocalStorage,
} from 'utils/utils';
import { Operation as PaymentOperation } from 'reducer/payment/payment';
import { routes } from 'const/routes';
import { getPaymentObject } from 'utils/basket';
import { ticketFormatsSetter } from 'utils/goods';
import { returnAuthConfig } from 'utils/auth';
import { AuthorizationStatus } from 'const/const';
import { getActualGoodsUpdateData } from 'components/Basket/Basket';

const initialState = {
  goods: [],
  delivery: [],
  ticketFormats: {},
  requiredTicketCountError: '',
  currentRemoveGoods: {},
  insurancesList: [],
  updateGoods: [],
};

export const ActionType = {
  ADD_GOODS: `ADD_GOODS`,
  UPDATE_GOODS_BASKET: `UPDATE_GOODS_BASKET`,
  ADD_SHOP: `ADD_SHOP`,
  UPDATE_GOODS: `UPDATE_GOODS`,
  REMOVE_GOODS: `REMOVE_GOODS`,
  REMOVE_ALL_GOODS: `REMOVE_ALL_GOODS`,
  SET_TICKET_FORMATS: `SET_TICKET_FORMATS`,
  SET_REQUIRED_TICKET_COUNT_ERROR: `SET_REQUIRED_TICKET_COUNT_ERROR`,
  REMOVE_CURRENT_GOODS: 'REMOVE_CURRENT_GOODS',
  GET_INSURANCES_LIST: 'GET_INSURANCES_LIST',
  GET_USER_BASKET_GOODS: 'GET_USER_BASKET_GOODS',
  UPDATE_USER_BASKET_GOODS: 'UPDATE_USER_BASKET_GOODS',
  GET_DELIVERY_BY_ITEM_TYPE: 'GET_DELIVERY_BY_ITEM_TYPE',
};

export const ActionCreator = {
  addGoods: (goods) => ({
    type: ActionType.ADD_GOODS,
    payload: goods,
  }),
  setDeliveryByItemType: (delivery) => ({
    type: ActionType.GET_DELIVERY_BY_ITEM_TYPE,
    payload: delivery,
  }),
  updateGoodsBasket: (goods) => ({
    type: ActionType.UPDATE_GOODS_BASKET,
    payload: goods,
  }),
  getUserBasketGoods: (goods) => ({
    type: ActionType.GET_USER_BASKET_GOODS,
    payload: goods,
  }),
  updateUserBasketGoods: (goods) => ({
    type: ActionType.UPDATE_USER_BASKET_GOODS,
    payload: goods,
  }),

  addShop: (item) => ({
    type: ActionType.ADD_SHOP,
    payload: item,
  }),

  updateGoods: (goods) => ({
    type: ActionType.UPDATE_GOODS,
    payload: goods,
  }),

  removeGoods: (item) => ({
    type: ActionType.REMOVE_GOODS,
    payload: item,
  }),
  removeCurrentGoods: (item) => ({
    type: ActionType.REMOVE_CURRENT_GOODS,
    payload: item,
  }),
  setInsurancesList: (item) => ({
    type: ActionType.GET_INSURANCES_LIST,
    payload: item,
  }),
  removeAllGoods: () => ({
    type: ActionType.REMOVE_ALL_GOODS,
  }),

  setTicketFormats: (formats) => ({
    type: ActionType.SET_TICKET_FORMATS,
    payload: formats,
  }),

  setRequiredTicketCountError: (error) => ({
    type: ActionType.SET_REQUIRED_TICKET_COUNT_ERROR,
    payload: error,
  }),
};

export const Operation = {
  checkOrder: (id, navigate) => async (dispatch, _, api) => {
    const response = await api.get(`/api/order/${id}`);

    function checkGoodsInLocalStorage(
      tickets = [],
      shop = [],
      certificates = [],
      insurances = []
    ) {
      // КОСТЫЛИ #TODO DEL
      tickets.forEach((el) => {
        const newEl = { ...el, public_id: el.format_public_id };
        updateLocalStorage(`basketGoods`, `delete`, newEl);
        dispatch(ActionCreator.removeGoods(newEl));
      });

      insurances.forEach((el) => {
        const newEl = { ...el, public_id: el?.insurance?.public_id };
        updateLocalStorage(`basketGoods`, `delete`, newEl);
        dispatch(ActionCreator.removeGoods(newEl));
      });

      // #CERTIFICATES DEL
      certificates.forEach((el) => {
        const newEl = { ...el, public_id: el.format_public_id };
        updateLocalStorage(`basketGoods`, `delete`, newEl);
        dispatch(ActionCreator.removeGoods(newEl));
      });

      // #SHOP DEL
      shop.forEach((el) => {
        const newEl = { ...el, selector: `shop`, size: el.name };
        updateLocalStorage(`basketGoods`, `delete`, newEl);
        dispatch(ActionCreator.removeGoods(newEl));
      });
      const remainingGoods = JSON.parse(localStorage.getItem(`basketGoods`));

      return remainingGoods;
    }

    if (response.status === 200) {
      const { items } = response.data;
      const {
        tickets = [],
        shop = [],
        certificates = [],
        insurances = [],
      } = items;

      const otherPayment = (array) => {
        if (array.length) {
          const goods = JSON.parse(localStorage.getItem('basketGoods'));
          const sendGoods = getPaymentObject(goods, array, items.email);

          dispatch(PaymentOperation.payment(sendGoods));
        }
      };

      // Если в корзине остались НЕоплаченные товары/билеты
      if (
        checkGoodsInLocalStorage(tickets, shop, certificates, insurances).length
      ) {
        otherPayment(
          checkGoodsInLocalStorage(tickets, shop, certificates, insurances)
        );
      }

      // Если куплен сертификат, редирект на Certificate Thanks
      else if (certificates.length >= 1) {
        navigate(routes.certificateThanks.path);
      } else {
        // Если в корзине НЕ осталось НЕоплаченных товаров/билетов
        // И пользователь авторизирован
        if (localStorage.userObj) {
          if (tickets.length === 0 && shop.length >= 1) {
            navigate(routes.myOrders.path);
          } else if (response.data.tickets.length > 1) {
            navigate(routes.myEvents.path);
          } else {
            navigate(
              `${routes.myEvents.path}/ticket/${response.data.tickets[0]}`
            );
          }
        }
      }
    }
  },
  loadInsurancesList: (public_id) => async (dispatch, _, api) => {
    const response = await api.get(
      `/api/event_format/active/insurances/${public_id}`
    );
    if (response.status === 200) {
      dispatch(ActionCreator.setInsurancesList(response.data.values));
    }
  },

  getUserBasketGoods: () => async (dispatch, getState, api) => {
    if (getState().USER?.authorizationStatus === AuthorizationStatus.AUTH) {
      const config = returnAuthConfig();
      const response = await api.get(`/api/user/basket`, config);

      if (response.status === 200) {
        if (response.data) {
          if (response.data.values?.length) {
            response.data.values.forEach((el, i) => {
              if (i !== response.data.values.length - 1) {
                if (el.selector === `shop`) {
                  dispatch(Operation.addShop(el, {}, false));
                } else {
                  dispatch(Operation.addGoods(el, {}, false));
                }
              } else {
                if (el.selector === `shop`) {
                  dispatch(Operation.addShop(el));
                } else dispatch(Operation.addGoods(el));
              }
            });
          }
        } else {
          dispatch(ActionCreator.getUserBasketGoods([]));
        }
      }
    }
  },
  updateUserBasketGoods:
    (goods, navigateTo = () => {}) =>
    async (dispatch, getState, api) => {
      if (getState().USER?.authorizationStatus === AuthorizationStatus.AUTH) {
        const config = returnAuthConfig();

        try {
          const response = await api.post(`/api/user/basket`, goods, config);

          if (response.status === 200) {
            navigateTo();
          }
        } catch (e) {
          navigateTo();
        }
      } else {
        navigateTo();
      }
    },

  // #TODO - ОБНОВИТЬ STATE - для билетов!
  addShop:
    (item, navigateTo, isUpdate = true) =>
    async (dispatch, getState) => {
      dispatch(ActionCreator.addShop(item));
      updateLocalStorage(`basketGoods`, `add`, item);
      if (
        getState().USER?.authorizationStatus === AuthorizationStatus.NO_AUTH
      ) {
        updateLocalStorage(`NoAuthBasketGoods`, `add`, item);
      }
      if (isUpdate) {
        dispatch(
          Operation.updateUserBasketGoods(
            getActualGoodsUpdateData(getState()?.BASKET?.goods),
            navigateTo
          )
        );
      }
    },
  addGoods:
    (item, navigateTo, isUpdate = true) =>
    async (dispatch, getState) => {
      dispatch(ActionCreator.addGoods(item));
      updateLocalStorage(`basketGoods`, `add`, item);
      if (
        getState().USER?.authorizationStatus === AuthorizationStatus.NO_AUTH
      ) {
        updateLocalStorage(`NoAuthBasketGoods`, `add`, item);
      }
      if (isUpdate) {
        dispatch(
          Operation.updateUserBasketGoods(
            getActualGoodsUpdateData(getState()?.BASKET?.goods),
            navigateTo
          )
        );
      }
    },

  removeShop:
    (item, navigateTo, isUpdate = true) =>
    async (dispatch, getState) => {
      dispatch(ActionCreator.removeGoods(item));
      updateLocalStorage(`basketGoods`, `delete`, item);
      updateLocalStorage(`NoAuthBasketGoods`, `delete`, item);
      if (isUpdate) {
        dispatch(
          Operation.updateUserBasketGoods(
            getActualGoodsUpdateData(getState()?.BASKET?.goods),
            navigateTo
          )
        );
      }
    },

  /* checkTicketsBeforePayment:
    (event_id, city_id, hidden = false) =>
    async (dispatch, _, api) => {
      const response = await api.get(
        `/api/event_format/event/${event_id}/${city_id}${
          hidden ? "?hidden=true" : ""
        }`
      );

      if (response.status === 200) {
        dispatch(ActionCreator.setTicketFormats(response.data.values));
      } else if (response.status >= 400) {
        backendLogger(response);
      }
    }, */
  checkTicketsBeforePaymentByFormatId:
    (format_id) => async (dispatch, _, api) => {
      const response = await api.get(`/api/event_format/${format_id}`);

      if (response.status === 200) {
        dispatch(ActionCreator.setTicketFormats(response.data));
      } /*  else if (response.status >= 400) {
        backendLogger(response);
      } */
    },
  getDeliveryByItemType: (item_type) => async (dispatch, _, api) => {
    const response = await api.get(`/api/delivery/${item_type}`);

    if (response.status === 200) {
      dispatch(ActionCreator.setDeliveryByItemType(response.data.values));
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADD_GOODS:
      return {
        ...state,
        goods: returnNewArrayById(state.goods, action.payload),
      };
    case ActionType.GET_USER_BASKET_GOODS:
      return {
        ...state,
        goods: action.payload,
      };
    case ActionType.GET_DELIVERY_BY_ITEM_TYPE:
      return {
        ...state,
        delivery: action.payload,
      };
    case ActionType.UPDATE_GOODS_BASKET:
      return {
        ...state,
        updateGoods: action.payload,
      };
    case ActionType.ADD_SHOP:
      return {
        ...state,
        goods: returnNewShopItem(state.goods, action.payload),
      };
    case ActionType.UPDATE_GOODS:
      return {
        ...state,
        goods: updateObjectInArray(state.goods, action.payload),
      };
    case ActionType.REMOVE_GOODS:
      return {
        ...state,
        goods: removeItemBasket(state.goods, action.payload),
      };
    case ActionType.REMOVE_ALL_GOODS:
      return { ...state, goods: [] };
    case ActionType.SET_TICKET_FORMATS:
      return {
        ...state,
        ticketFormats: ticketFormatsSetter(state.ticketFormats, action.payload),
      };
    case ActionType.GET_INSURANCES_LIST:
      return { ...state, insurancesList: action.payload };
    case ActionType.REMOVE_CURRENT_GOODS:
      return { ...state, currentRemoveGoods: action.payload };

    default:
      return state;
  }
};
