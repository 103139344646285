
import { getDate } from "customHooks/useValidationDate";
import { capitalizeFirstLetter } from "utils/utils";
import styles from "./MyInsurance.module.scss";

export const Insurance = ({ insurance }) => {


    return <div className={styles.insuranceWrapper}>
        <p>ФИО: {capitalizeFirstLetter(insurance.info.last_name)} {capitalizeFirstLetter(insurance.info.first_name)} {insurance.info?.second_name ? capitalizeFirstLetter(insurance.info.second_name) : ""}</p>
        <p>Дата рождения: {getDate(insurance.info.birth_date * 1000)}</p>
        <p>Пол: {insurance.info.gender === "male" ? "Мужской" : insurance.info.gender === "female" ? "Женский" : ""}</p>
        <p>Телефон: {`+${insurance.info.phone}`}</p>
    </div>;
};