import Container from "components/App/Container/Container";
import { Operation, ActionCreator } from "reducer/data/data";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUserInsurances, getPastUserInsurances } from "reducer/data/selectors";
import { useEffect, useState } from "react";
import { Insurance } from "./Insurance";

import styles from "./MyInsurance.module.scss";

export const MyInsurances = () => {

    const [isPastInsuranceTitleClick, setIsPastInsuranceTitleClick] = useState(false);

    const insurancesActive = useSelector((state) => getActiveUserInsurances(state)) ?? [];
    const insurancesPast = useSelector((state) => getPastUserInsurances(state)) ?? [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Operation.loadActiveUserInsurances());

        return () => ActionCreator.setActiveUserIncurances([]);
    }, []);

    const handlePastClick = () => {
        setIsPastInsuranceTitleClick(!isPastInsuranceTitleClick);
        dispatch(Operation.loadPastUserInsurances());
    };

    return <Container>

        <h2 className={styles.title}>Страховки</h2>

        <h3 className={styles.label}>Активные страховки</h3>

        {insurancesActive.length ? insurancesActive.map((el, i) => <Insurance key={i} insurance={el}></Insurance>) : <h3>Нет данных</h3>}

        <div className={styles.pastInsurances}>
            <h3 onClick={handlePastClick} className={`${styles.label} ${!isPastInsuranceTitleClick && styles.underlineTitle}`}>Предыдущие страховки</h3>
            {isPastInsuranceTitleClick && <div>{insurancesPast.length ? insurancesPast.map((el, i) => <Insurance key={i} insurance={el}></Insurance>) : <h3>Предыдущие страховки не найдены!</h3>}</div>}
        </div>
    </Container>;
};