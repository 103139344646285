import Input from "components/Forms/Input/Input";
import InputPhone from "components/Forms/Input/InputPhone";
import Datepicker from "components/Forms/Datepicker/Datepicker";
import Radio from "components/Forms/Radio/Radio";
import Message from "components/Auth/Registration/Message/Message";
import styles from "../Goods.module.scss";
import moment from "moment-timezone";
import { getDate, useValidationDate } from "customHooks/useValidationDate";
import { useEffect, useState } from "react";
import { ReactComponent as DateLogo } from "images/profile/profile-calendar.svg";
import { ReactComponent as ReactLogoName } from "images/profile/profile-name.svg";
import { REACT_APP_API } from "api";
import { updateLocalStorage } from "utils/utils";
import { useValidationPhone } from "customHooks/useValidationPhone";
import { useDispatch } from "react-redux";
import { ActionCreator } from "reducer/basket/basket";

export const InsuranceForm = ({ insurance }) => {
    const dispatch = useDispatch();
    const userObj = localStorage.getItem("userObj") ? JSON.parse(localStorage.getItem("userObj")) : {};
    const [valueDate, handleDateChange, date, isValidDate] =
        useValidationDate(insurance.info?.birth_date || userObj.birth_date && getDate(userObj.birth_date * 1000) || "");
    const [phone, isValidTel, handlePhoneChange,] = useValidationPhone(insurance.info?.phone && `+${insurance.info.phone}` || userObj.phone && `+${userObj.phone}`);

    const [isErrors, setErrors] = useState({
        date: "",
    });
    const [data, setData] = useState({
        first_name: insurance?.first_name || userObj.name,
        last_name: insurance?.last_name || userObj.last_name,
        second_name: insurance?.second_name || userObj.second_name,
        gender: insurance?.gender || userObj.gender,
    });

    const handleRadioChange = (e) => {
        setErrors({ ...isErrors, gender: false });
        setData({ ...data, gender: e.target.value });
    };

    useEffect(() => {

        const { first_name, last_name, gender, second_name } = data;

        if (!first_name) {
            setErrors((prev) => ({ ...prev, first_name: "Заполните поле!" }));
        } else {
            setErrors((prev) => ({ ...prev, first_name: "" }));
        }
        if (!last_name) {
            setErrors((prev) => ({ ...prev, last_name: "Заполните поле!" }));
        } else {
            setErrors((prev) => ({ ...prev, last_name: "" }));
        }
        if (!isValidTel) {
            setErrors((prev) => ({ ...prev, phone: "Введите корректный номер телефона!" }));
        } else {
            setErrors((prev) => ({ ...prev, phone: "" }));
        }
        if (!isValidDate) {
            setErrors((prev) => ({ ...prev, birth_date: "Введите корректную дату!" }));
        } else {
            setErrors((prev) => ({ ...prev, birth_date: "" }));
        }
        if (!gender) {
            setErrors((prev) => ({ ...prev, gender: "Выберите одно из значений!", }));
        }

        if (first_name && last_name && isValidTel && isValidDate && gender) {

            let newObj = {
                ...insurance,
                last_name,
                first_name,
                phone: phone?.slice(1),
                count: insurance.count,
                /* default_birthday: date, */
                gender,
                insurance: {
                    public_id: insurance.public_id,
                },
                birth_date: moment.utc(date).toISOString(),
                preReg: "ok"
            };

            if (second_name) {
                newObj = {
                    ...newObj,
                    second_name
                };
            }

            updateLocalStorage(`basketGoods`, `add`, newObj);
            dispatch(ActionCreator.updateGoods(newObj));
        }

    }, [data, phone, date]);


    return (
        <form style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <div className={styles.formWrap}>
                {/*   <img style={{ marginTop: "20px", marginBottom: "20px" }} src={`${REACT_APP_API}${insurance.image}`} alt="фото пример лицензии" /> */}
                <h2>Регистрация страховки</h2>
                <p style={{ marginTop: "1rem" }}>Особенно внимательно заполняйте эти поля. Следите за тем, чтобы тут были указаны именно ВАШИ контактные данные.</p>
                <p style={{ marginTop: "1rem" }}>Эти данные будут сопоставлены с уже имеющимися у нас и если в нашей базе будет обнаружен указанный Вами e-mail, телефон или ФИО, то часть данных мы возьмем из имеющейся базы.</p>

                <div className={styles.wrapperForm} style={{ marginTop: "1rem" }}>
                    <Input
                        error={isErrors.first_name}
                        handleFieldChange={(e) => setData({ ...data, first_name: e.target.value })}
                        handleFocus={() => setErrors({ ...isErrors, first_name: "" })}
                        Logo={ReactLogoName}
                        label={"Ваше имя*"}
                        name={"first_name"}
                        value={data?.first_name}
                    />
                    <Input
                        error={isErrors.second_name}
                        handleFieldChange={(e) => setData({ ...data, second_name: e.target.value })}
                        handleFocus={() => setErrors({ ...isErrors, second_name: "" })}
                        Logo={ReactLogoName}
                        label={"Ваше отчество (если есть)"}
                        name={"second_name"}
                        value={data?.second_name}
                    />
                    <Input
                        error={isErrors.last_name}
                        handleFieldChange={(e) => setData({ ...data, last_name: e.target.value })}
                        handleFocus={() => setErrors({ ...isErrors, last_name: "" })}
                        Logo={ReactLogoName}
                        label={"Ваша Фамилия*"}
                        name={"last_name"}
                        value={data?.last_name}
                    />

                    <Datepicker
                        error={isErrors.birth_date}
                        outsideStyle={styles}
                        value={valueDate}
                        handleDateChange={handleDateChange}
                        handleDateFocus={() => setErrors({ ...isErrors, birth_date: "" })}
                        Logo={DateLogo}
                        label={"Дата рождения*"}
                        id={"birth_date"}
                    />
                    <InputPhone
                        country="RU"
                        international={true}
                        withCountryCallingCode={true}
                        error={isErrors.phone}
                        label={"Ваш телефон*"}
                        type="tel"
                        name="phone"
                        value={phone}
                        onFocus={() => setErrors({ ...isErrors, phone: "" })}
                        onChange={handlePhoneChange}
                    />


                    <div className={styles.radioWrapper}>
                        <p className={styles.genderTitle}>
                            Ваш пол:
                        </p>
                        <ul className={styles.genderList}>
                            <li className={styles.genderItem}>
                                <Radio
                                    error={isErrors.gender}
                                    initialValue={'male'}
                                    value={data?.gender}
                                    name={'gender-radio'}
                                    id={new Date() + Math.random()}
                                    label={'Мужской'}
                                    handleRadioChange={handleRadioChange}
                                />
                            </li>
                            <li className={styles.genderItem}>
                                <Radio
                                    error={isErrors.gender}
                                    initialValue={'female'}
                                    value={data?.gender}
                                    name={'gender-radio'}
                                    id={new Date() - 1000 - Math.random()}
                                    label={'Женский'}
                                    handleRadioChange={handleRadioChange}
                                />
                            </li>
                        </ul>

                        {isErrors.gender?.length ? (
                            <Message message={isErrors.gender} styles={styles} />
                        ) : null}
                    </div>

                </div>
            </div>
        </form>
    );
};